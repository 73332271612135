import React from 'react';
import {InputRadiobox} from '..'
 
export function RadioBox (props) {
  const radio=props.radio
  
  return (
    <div className='form-settings__block_row'>
      <label className='meals__label font20Norm' htmlFor='everyday'>
        <input type="radio" value={'everyday' || ''} checked={radio ==='everyday' ? true : false} className='meals__checkbox' onChange={props.onChange}  name='period' id='everyday' />
        <span className="visible-checkbox item__link"></span>
        Каждый день
      </label>
      <label className='meals__label font20Norm' htmlFor='two'>
        <input type="radio" value={'two' || ''} checked={radio ==='two' ? true : false} className='meals__checkbox' onChange={props.onChange}  name='period' id='two' />
        <span className="visible-checkbox item__link"></span>
        Через день
      </label>
      <label className='meals__label font20Norm' htmlFor='three'>
        <input type="radio" value={'three' || ''} checked={radio ==='three' ? true : false} className='meals__checkbox' onChange={props.onChange}  name='period' id='three' />
        <span className="visible-checkbox item__link"></span>
        Раз в 3 дня
      </label>
      </div>
  )
}
