import React, { useContext } from "react"; 
import Dot from "./Dot";
import './Dot.css';

export function Dots(props) {
  const slidesCount = props.slidesCount;
  
  const renderDots = () => {
    const dots = [];
    for (let i = 0; i < slidesCount; i++) {
      dots.push(<Dot key={`dot-${i}`} goToSlide={props.goToSlide} slideNumber={props.slideNumber} number={i} />);
    }

    return dots;
  };

  return <div className="dots">{renderDots()}</div>;
}