

import React from 'react';
import { ChoiceInRecipe } from '../../organisms'

export function AddRecipeInMenu (props) {
 
  return (
    <section className={`modal ${props.isOpen ? ('modal_opened') : ''}`}> 
      <div  className='add-recipe'>
        <button onClick={props.onClose} type="button" className='gg-close item__link' aria-label="Закрыть форму"></button>
        <h1 className='add-recipe__heading'>{props.positionMenuItem.powerModeRu}</h1>
        <ChoiceInRecipe 
          addRecipe={props.addRecipe} 
          replaceRecipe={props.replaceRecipe} 
          replace={props.replace} 
          closeModal={props.closeModal} 
          positionMenuItem={props.positionMenuItem}
        /> 
      </div>
    </section>
)
}