//Регистрация пользователя

import React from 'react';
import { useParams } from "react-router-dom";
import Form from '../../organisms/Form/Form'
import {useSelector} from 'react-redux'
import './SignUp.css'
import {useFormValidation} from '../../../utils/Validator.js';
import {pattern, subscriptions} from '../../../constants/constants';

function SignUp(props) {
  const { lib, statusLib } = useSelector(state => state.lib) 
  const submitAuth = props.submitAuth;
  const { values, handleChange, errors, isValid, emailError, nameError,
    passwordError, errorsConfirmation,
  } = useFormValidation({
    email: '', password: '', name: '' });
  const submitDisabled = values.email === '' || values.password === '' || !isValid || submitAuth || statusLib === 'loading' || passwordError || errorsConfirmation || emailError;
  const { subs } = useParams();
  const subscription = subscriptions.find(i=> i.subs === subs)

  function handleSubmit(e) {
    e.preventDefault();
    props.onRegister(values.email, values.password, values.name, subscription) 
  }  
  
  return (
    <Form 
    title="РЕГИСТРАЦИЯ" 
    buttonName="Зарегистрироваться" 
    text="Уже зарегистрированы?"
    sign="Войти"
    rout="/signin"
    onRegister={props.onRegister}
    email={values.email}
    password={values.password}
    passwordConfirmation={values.passwordConfirmation}
    onSubmit={handleSubmit}
    onChange={handleChange}
    errorsEmail={emailError}
    errorsConfirmation={errorsConfirmation}
    errorsPassword={passwordError}
    submitDisabled={submitDisabled}
    >
    <label className="input__label" htmlFor="name"></label>
    <input 
    onChange={handleChange} 
    autoComplete="off" 
    value={values.name} 
    type="text"
    className="form__item form__item_type_name" 
    name="name" 
    placeholder="Имя" 
    id="name" 
    required 
    minLength="2" 
    maxLength="40"
    pattern={pattern.name}
    />
    {nameError && <span className="font16Norm form__item-error">{nameError}</span>}   
    
    </Form>    
  )
}
 
export default SignUp;