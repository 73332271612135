//Мини карта с рецептом(обложка)

import React from 'react';
import './Recipe.css';
import {Link} from 'react-router-dom'
import { PricePortion, WeightPortion } from '../../molecules';
import { ModalChoiceAsk } from '../';
import { TypeDiet } from '../../atoms'
import {useSelector, useDispatch} from 'react-redux'  
import { changeLikeRecipe, deleteRecipe } from '../../../store/api';
import { sortAlf, totals } from '../../../utils/utils'; 

export function Recipe(props) {
  const {ingredients, status} = useSelector(state => state.ingredients)
  const {recipe, statusLike } = useSelector(state => state.recipe)
 // const recipe = props.recipe 
  const dispatch = useDispatch()
  const positionMenuItem = props.positionMenuItem
  let id = props.recipe._id
  const { currentUser } = useSelector(state => state.currentUser)
  const userlikes = props.recipe.likes.some(i => i === currentUser._id);
  const owner = props.recipe.owner === currentUser._id;
  let total = status === 'resolved' && totals(props.recipe, ingredients) 
  const [tooltip, setTooltip] = React.useState('');
  const [isLiked, setIsLiked] = React.useState(userlikes)


  React.useEffect(()=> {  
    if(statusLike === 'resolved' && recipe._id === props.recipe._id) {
    setIsLiked(!isLiked) 
    } 
  },[statusLike])

  //Фильтрование типов питания(тегов)
  const typeOfDiet =  sortAlf(props.recipe.typeOfDiet.filter((item)=>{
    return item.boolean
  }))

  //Удаление рецептов
  function handleDelete() {
    props.handleDelete(props.recipe) 
  }

  // редактирование рецептов
  function handleEditRecipe() {
    props.editRecipe(props.recipe)
  }


  // Добавление и удаление лайков
  function handleLikeClick () {
    dispatch(changeLikeRecipe({id:props.recipe._id, isLiked: !isLiked}))
  }

  function handleAddRecipe() {
    if(Object.keys(props.positionMenuItem).length !== 0) {
      if(!props.positionMenuItem.replace) {
          props.addRecipe(
            props.recipe,
            positionMenuItem.powerMode,
            positionMenuItem.item
          )
          props.closeModal()
      } else if(props.positionMenuItem.replace) { 
        props.replaceRecipe(
          positionMenuItem.id,
          props.recipe,
          positionMenuItem.powerMode,
          positionMenuItem.item
        )
          props.closeModal()

      } 
    } else {
      //setIsOpen(true)
    }
  } 

 
  return (
    <article className="recipe-card__chess">
      <div className='recipe-card__chess_content'>
        <Link className="recipe__link" target="_blank" to={`${props.recipe._id}`}>
          <img className="recipe-card__chess_image" src={props.recipe.image[0]} alt={props.recipe.name} />
        </Link>
        <div className='font20Norm color chess__category' >{props.recipe.category}</div>
        </div>
          <div className="card-body">
          <div className='recipe__heading chess__heading' >
            <ul className='recipe__icon chess__icon' >
            {props.loggedIn &&
              <li className={`item__link ${isLiked ? 'gg-active-like' : 'gg-like'}`} onClick={handleLikeClick}></li>
            }
            {(owner || currentUser.supervisor) &&
            <>
              <li className={`${props.type === 'inMenu' ? 'gg-none' : 'gg-delete l-size'}`}  onClick={handleDelete}></li>
              <li className={`${props.type === 'inMenu' ? 'gg-none' : 'gg-edit l-size'}`} onClick={handleEditRecipe}></li>
              </>
            }
              <li className='chess__time'>
                <span className='gg-clock'/>
                <span className='gg-time font20Norm'>{props.recipe.time} мин</span>
              </li>
            </ul>
           
          </div>
          <Link to={`${props.recipe._id}`} target="_blank" className="chess__title font20Bold">{props.recipe.name}</Link>
       
        <div className="diets">
          {typeOfDiet &&
            typeOfDiet.map((i)=>( 
            <TypeDiet key={i.nameEn} tooltip={tooltip} item={i.nameRu} /> 
            )) 
          }
        </div>
        
        {ingredients.length>0 &&
        <div className='portion'>
           <div className='portion__calc'>
          <h3 className='portion__title  font16Norm'><span className='gg-dish m-size'></span>:</h3> 
            <PricePortion portion={props.recipe.servings} ingredients={ingredients} recipe={props.recipe.ingredients}/>
            <WeightPortion total={Math.round(total/props.recipe.servings)}/>
            <div className='portion__item font16Norm'>
              <span className="portion__name">Ккал</span>
              <span className='portion__num'>{Math.round(props.recipe.calories.calories/props.recipe.servings)} Ккал</span>
            </div> 
          </div>
        </div>
        }
      </div> 
      <button onClick={handleAddRecipe} className={`button__unvisible ${positionMenuItem ? ' gg-choice' : ''} `}/> 
    </article>
  )  
}
