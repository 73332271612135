//Дополнительно меню для авторизированных пользователей

import React from 'react';
import './Sidebar.css';
import { ProfileItem, InfoPopup } from '../../atoms';
import { profileCategory } from '../../../constants/constants';
 
export const Sidebar = React.forwardRef((props, ref) => (
  <InfoPopup
    isOpen={props.isOpen}
    ref={ref}
    onSignOut={props.onSignOut}
    type='nav'
  >
    {profileCategory.map((item)=> (
      <ProfileItem item={item} onClick={props.handleClickMenu} onSignOut={props.onSignOut} key={item.id} />
    ))}
  </InfoPopup> 
));
