//Инструкция

import React from 'react';
import './Leading.css';
import traditional from '../../../images/traditional-type.jpg';
import vegan from '../../../images/vegan.jpg';
import keto from '../../../images/keto.jpeg';
import table from '../../../images/table.jpg';
import whole from '../../../images/whole.jpg';
import child from '../../../images/child.jpg';

export function Leading (Types) {
  
  return (
    <section className='leading'>
      <p className='leading__title leading__title_first font26Bold'>Вы устали думать что приготовить?</p>
      <p className='leading__title font26Bold'>При закупке продуктов 
      <span className='leading__sub'> забываете что-то купить?</span></p>
        
      <p className='leading__title font26Bold'>Вы на диете и в поиске рецептов, чтобы
          <span className='leading__sub'> разнообразить свой рацион?</span></p>
     
      <p className='leading__title font26Bold'>Каждый раз выбрасываете проукты, 
      <span className='leading__sub'> так как не дошли руки приготовить?</span></p>

     
       
     
    </section>
)
   
}