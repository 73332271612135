// Страница о сервисе

import React from 'react';
import './Home.css';
import {useSelector } from 'react-redux'
import {Promo, MainVideo } from '../../molecules'
import { SloganSlider, Manual, Types, Leading } from '../../organisms'

export function Home() {
 
  return (
    <>
      <Promo />
      <SloganSlider />
      <Types />
      <Leading />
      <Manual /> 
    </>
  )  
}
