//Разметка кол-ва персон
import './InputCounter.css'

import React from 'react';

export function InputCounter (props) {

  return (
    <div className='form__block form__block_count' htmlFor='capacity'>
      <legend  className='form-settings__title font20Bold'>{props.title}</legend>
      <label className='counter__label'>
        <button 
          type='button' 
          onClick={props.handleCountClickMinus} 
          className='counter__button counter__button_type_minus'></button> 
        <input 
          disabled={true} 
          type='text' 
          value={props.value || ''} 
          required onChange={props.onChange} 
          className='counter__input counter__input_members' 
          name={props.name}
          id={props.name}/>
        <button type='button' onClick={props.handleCountClickPlus} className='counter__button counter__button_type_plus'></button> 
      </label>
    </div>
  )
}

