import React from 'react';
import './Cal.css'
import {InfoPopup} from '../'
import {popupMessage} from '../../../constants/constants.js'
export function Cal (props) {
  let norm = props.name === 'Белки' ? 120 : props.name === 'Жиры' ? 64 : props.name === 'Угдеводы' ? 160 
  : props.name === 'Калории' ? 2000 : ''
  const calcul = props.name === 'Вес' ? '' : Math.ceil((props.num*100)/norm)
  const [isOpen, setIsOpen] = React.useState(false);

  const handleMouseOver = () => {
    setIsOpen(true);
  };

  const handleMouseOut = () => {
    setIsOpen(false);
  };

  return (
    <div className='cal' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <span className='cal__title'>{props.name}</span>
      <span className='cal__num font16Norm'>{props.num} {props.measure}</span>
      <span className='cal__perc font16Norm'>{calcul}%</span>
      <InfoPopup 
        handleClickMenu={props.handleClickMenu}
        isOpen={isOpen}
        onSignOut={props.onSignOut}
      >
        <h3 className='popup__title'>{props.name}</h3>
        <p className='popup__text font16Norm'> {popupMessage[props.name]}</p>
      </InfoPopup> 
     </div>
  )
}