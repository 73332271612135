import React from 'react'; 
import './Slogan.css';
import { SloganBlock } from "../../atoms"; 
import { Slider } from '../../molecules'
import {mainTesises} from '../../../constants/constants'

export function SloganSlider(props) {
  const arr= mainTesises
  const [step, setStep] = React.useState(2)
  const [items, setItems] = React.useState(mainTesises);
  const [slide, setSlide] = React.useState(0); 
  const nums = items.length %step === 0 ? items.length/step : (items.length+1)/step
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  function checkWindowWidth() {
    setTimeout(() => setWindowWidth(window.innerWidth), 500);
  };

  const goToSlide = (number) => {
    setSlide(number % nums);
  }; 
  
  React.useEffect(()=> {
    window.addEventListener('resize', checkWindowWidth)
    if(window.innerWidth > 520) {
        setStep(2);
      } else if (window.innerWidth < 521) {
        setStep(1);
      }
      return () => window.removeEventListener('resize', checkWindowWidth);
  
  }, [windowWidth])

  const changeSlide = (direction = 1) => {
    let slideNumber = 1;
    if (slide + direction < 0) {
      slideNumber = nums - 1;
    } else {
      slideNumber = (slide + direction)% nums
    }

    setSlide(slideNumber);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      changeSlide(1);
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [items.length, slide])
 
  return (
  <section className='slogan '>
     <Slider nums={nums}  slide={slide} step={step} length={items.length} title='slogan' offset= '100' goToSlide={goToSlide}>
       {items.map((item, index) => (
          <SloganBlock key={index} item={item} />
          ))
        } 
     
    </Slider> 
  </section>
  )  
}