//Строка поиска ингредиентов при добавлении рецепта

import { AutoComplete } from '../../../utils/utils'

export function InputIngredient(props) {

  return (
    <>
      <input 
        type='search' 
        value={props.ingredient || ''} 
        onChange={props.handleChangeInput} 
        placeholder='Начните вводить ингредиент' 
        className='add-recipe__input font20Norm' 
        name='ingredients' 
        id='ingredients'/>
      <span className='add-recipe__autocomplete'>
        {props.filtrMassiv.length > 0 && props.hidden &&
        props.filtrMassiv.map((item, index) => (
        <AutoComplete key={index} item={item} id='идентификатор' onClick={props.handleAutoText} />
        ))
        }
     </span>
    </>
  )
}