import * as React from 'react';
import { render } from 'react-dom';
import { SimpleCalendar } from './SimpleCalendar';
import styled from 'styled-components';
import './styles.css';



export function Calendars(props) {
  const today = new Date().toLocaleDateString()
  
  return (
    <section className='calendar '>
      <p className='calendar__today font20Norm color' onClick={props.handleCalendarVisibility}> Выберите даты</p>
      <SimpleCalendar 
        dateDay={props.dateDay} 
        userMenu={props.userMenu} 
        onDayRange={props.onDayRange} 
        isOpen={props.isOpen}
        getLocaldata={props.getLocaldata}
      />
     
    </section>
  );
}
