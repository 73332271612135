import React from "react";
import {ImageItem} from './ImageItem'
import './ImageBlocks.css'

export function ImageBlocks(props) {
  const arr= props.image
  const [counter, setCounter] = React.useState(0)
  const [newimg, setNewimg] = React.useState(arr[counter]);
  const [imageShow, setImageShow] = React.useState([]);
  const [startIndex, setStartIndex] = React.useState(0);
  const arrLength = arr.length-1 > counter

  React.useEffect(()=> {
    quantityMovies(arr, startIndex, startIndex+3)
  },[startIndex, arr])

  function handlerCounterUp() {
    arrLength ? setCounter(counter+1) : setCounter(0) 
    setNewimg(arr[counter])
  }
  function handlerCounterDown() {
    counter > 0 ? setCounter(counter-1) 
    : counter === 0 ? setCounter(arr.length-1)
    : setCounter(0)
    setNewimg(arr[counter])
   
  }
  function handleSelectImg(src) {
    setNewimg(src)
    setCounter(arr.indexOf(src)+1)
  }

  function quantityMovies(arr, start, finish) {
    setImageShow(arr.slice(start, finish))
  }

  function handleNextClick() {
    imageShow.length === 1 ? setStartIndex(0)
    : setStartIndex(startIndex+1)
  }

  function handleBeforeClick() {
    imageShow.length === 1 ? setStartIndex(0)
    : setStartIndex(startIndex-1)
  }

  function handleClick() {
    props.onCardClick(newimg);
  }
  
  return (
    <div className="image-show">
      <div className="image-show__block">
        {arr.length > 1 &&
          <div onClick={handlerCounterDown} className="arrow arrow_left"></div>
        }
        <img className={`image-medium ${props.imageLot < 2 ? 'image__full-size' : ''} `} onClick={handleClick}  src={newimg} alt={props.name} />
        {arr.length > 1 &&
          <div onClick={handlerCounterUp} className="arrow arrow_right"></div>
        }
      </div>  
      {arr.length > 1 &&
        <div className="image-show__mini">
          {imageShow.map((item, index) => (
            <ImageItem key={index} src={item} onClick={handleSelectImg}/>
          ))
          }
        </div>
      } 
    </div>
  );
}
 