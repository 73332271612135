//Кнопки типов питания

import React from 'react';
import {popupMessage} from '../../../constants/constants.js'
import { InfoPopup } from '../'  
  

export function TypeDiet (props) {
  const item= props.item 
  const [isOpen, setIsOpen] = React.useState(false);
  
  const handleMouseOver = () => {
    setIsOpen(true)
  };
  
    const handleMouseOut = () => {
      setIsOpen(false)
    };
  
  return (
    <div className='diet__block'>
      <span onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className="diet font16Norm itm__lienk">{item}</span>
     {/*} <InfoPopup 
       handleClickMenu={props.handleClickMenu}
       isOpen={isOpen}
       type='diets'
      >
        <h3 className='popup__title'>{item}</h3>
        <p className='popup__text font16Norm'> {popupMessage[item]}</p>
  </InfoPopup> */}
    </div>
  )
}