//Форма для заполнения данных для регистрации и авторизации

import React from 'react';
import './Form.css'
import { Link } from 'react-router-dom';
import {pattern} from '../../../constants/constants';
import eyeOn from '../../../images/eye_open.svg'
import eyeOff from '../../../images/eye_close.svg'

function Form(props) {
  const [type, setType] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [icon, setIcon] = React.useState(eyeOff);

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eyeOn);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
 }
 return (
  <main className='container'>
    <div className="form__container">
      <h2 className="form__title">{props.title}</h2>
      <form className="form__info" onSubmit={props.onSubmit} disabled={props.submitDisabled ? true : ''}>
        <fieldset className="form__data">
        {props.children}
          <label className="input__label" htmlFor="email"></label>
          <input 
          autoComplete="off" 
          type="email" 
          onChange={props.onChange} 
          value={props.email} 
          placeholder="Email" 
          className="form__item form__item_type_email" 
          name="email" 
          id="email" 
          pattern={pattern.email}
          required/>
          {props.errorsEmail && <span className="font16Norm form__item-error">{props.errorsEmail}</span>}
          
          <label className="input__label" htmlFor="password">
          <input 
            autoComplete="off" 
            type= {type}
            onChange={props.onChange} 
            value={props.password} 
            placeholder="Пароль" 
            className="form__item form__item_type_password" 
            name="password" 
            id="password" 
            required minLength="8" 
            maxLength="40"/>
          <span className="items-center" onClick={handleToggle}>
            <img className="eye-icon" src={icon} />
          </span>
          </label>
          {props.title === 'РЕГИСТРАЦИЯ' &&
          <>
           <label className="input__label" htmlFor="password">
           <input 
           autoComplete="off" 
           type="password" 
           onChange={props.onChange} 
           value={props.passwordConfirmation} 
           placeholder="Подтверждение пароля" 
           className="form__item form__item_type_password" 
           name="passwordConfirmation" 
           id="passwordConfirmation" 
           required minLength="8" 
           maxLength="40"/>
           {props.errorsConfirmation && <span className="font16Norm form__item-error">{props.errorsConfirmation}</span>}
           
            </label>
          </> 
         
          }
          {props.errorsPassword && <span className="font16Norm form__item-error">{props.errorsPassword}</span>}
          
        </fieldset>
        <button disabled={props.submitDisabled ? true : ''} className={`menuweek-button form__button color ${props.submitDisabled ? ('form__button_disabled') : 'section__link'}`} type="submit" aria-label={props.buttonName}>{props.buttonName}</button> 
      </form>
      <div className="form__signup">
        <p className="form__text">{props.text}</p>
        <Link to={`${props.rout}`} className="form__link section__link">{props.sign}</Link>
      </div>
    </div> 
  </main>
  )
}
 
export default Form;