import React from 'react';
import {useState, useCallback} from 'react';
import { useSelector } from 'react-redux'
import api from "./api";

export function bonusDay () {
  const today = new Date().getTime()
  const finishDay = new Date(2023, 0, 1).getTime()
  const bonusDay = Math.ceil((finishDay- today)/86400000)
  return bonusDay
}

//Автозаполнение инпута при выборе
export const AutoComplete = (props) => {
  function handleAutoText() {
    props.onClick(props.item)
  }
  return (
    <p className='add-recipe__autocomplete_text' onClick={handleAutoText}>{props.item.name} {props.item.trait}</p>
  )
}

//Открытие и закрытие модальных окон
export function OpenModal(namevalues) {
  const [isOpen, setIsOpen] = useState(false)
  const [editData, setEditData] =useState(false)
  const [showModal, setShowModal] = React.useState(false); 
  const [isOpenSet, setIsOpenSet] = React.useState(false);
  const [hoax, setHoax] = React.useState(false);
  
  const handleOpen = () => {
    setIsOpen(true)
  }; 
  //модальное информационного блока
  const handleOpenModal = () => {
    setShowModal(true)
  };

  const handleOpenEdit = () => {
    setEditData(true)
  };

  const handleHoax = () => {
    setHoax(false)
  };

 // модальная настроек
  function handleSettings() {
    setIsOpenSet(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    setEditData(false)
    setShowModal(false)
    setIsOpenSet(false)
    setHoax(true)
  };
  
  return { 
    isOpen, 
    setIsOpen,
    showModal,
    isOpenSet,
    hoax,
    handleClose,
    handleHoax,
    handleOpenModal, 
    handleSettings,
    handleOpen, 
    editData,
    handleOpenEdit,
    setEditData,
    setShowModal,
  }
}

                                            /* СЧЕТЧИКИ */
//Изменение количества при клике + или -
export function Counter (cap) {

  const [capacity, setCapacity] = useState(cap.capacity)

  //счетчик членов семьи в плюс
    function handleCountClickPlus() {
      setCapacity(capacity+1) 
    }
  
    //счетчик членов семьи в минус
    function handleCountClickMinus() {
      if (capacity > 1) {
        setCapacity(capacity-1)
      }
    }
 
  return {
    capacity,
    handleCountClickPlus,
    handleCountClickMinus
  }
}

export function AddsIngredient(namevalues) {
  //хук управления формой и валидации формы
  const {ingredients, status} = useSelector(state => state.ingredients)
  const [filtrMassiv, setFiltrMassiv] = useState([]);
   const [hidden, sethidden] = useState(false)
   const [hiddenEdit, sethiddenEdit] = useState(false)
   const [choiceIngredient, setChoiceIngredient] = useState([])
   const [name, setName] = useState('')
   const [weight, setWeight] = useState('')
   const [measure, setMeasure] = useState('')
   const [choice, setChoice] = React.useState(false)
   const [nameEdit, setNameEdit] = useState(namevalues.nameEdit)
   const [weightEdit, setWeightEdit] = useState(namevalues.weightEdit)
   const [measureEdit, setMeasureEdit] = useState(namevalues.measureEdit)
   const ingredient =  status === 'resolved' && ingredients.length> 0 && ingredients.find(f => f.name === choiceIngredient.name);
   const isDisabled = name === undefined || name === '' ||
   weight === undefined || weight === 0 ||
   measure === undefined || measure === ''
  function handleFiltrClick(keyword) {
    if(keyword!=='') {
    const newMassiv = filtrSearchArr(ingredients, keyword)
    setFiltrMassiv(newMassiv)
    } else {
      setFiltrMassiv([])
    }
  }
  
  function handleChoice() {
    setChoice(!choice)
  }

  function handleAutoText(text) {
    setName(text.name)
    setChoiceIngredient(text)
    sethidden(false)
  }
  function handleAutoEditText(text) {
    setNameEdit(text.name)
    setChoiceIngredient(text)
    sethiddenEdit(false)
  }
  const handleChangeName = (e) => {
    setName(e.target.value)
    sethidden(true)
    handleFiltrClick(e.target.value)
  }
 

  const handleChangeWeight = (e) => {
    setWeight(e.target.value)
    setChoiceIngredient({...choiceIngredient, weight: e.target.value})
  }

  const handleChangeMeasure = (e) => {
    setMeasure(e.target.value)
    setChoiceIngredient({...choiceIngredient, choisMeasure: e.target.value})
  }

  const handleChangeEditName = (e) => {
    setNameEdit(e.target.value)
    sethiddenEdit(true)
    handleFiltrClick(e.target.value)
  }

  const handleEditIngredient = (text) => {
    setNameEdit(text.name)
    setWeightEdit(text.weight)
    setMeasureEdit(text.choisMeasure)
  }
  const handleChangeEditWeight = (e) => {
    setWeightEdit(e.target.value)
    setChoiceIngredient({...choiceIngredient, weight: e.target.value})
  }
  
  const handleChangeEditMeasure = (e) => {
    setMeasureEdit(e.target.value)
    setChoiceIngredient({...choiceIngredient, choisMeasure: e.target.value})
  }
  
  const resetIngForm = useCallback(()=> {
    setName('');
    setWeight('');
    setMeasure('')
    setChoiceIngredient('');
  }, []);

  return { 
    name,
    nameEdit,
    weightEdit,
    setWeightEdit,
    measureEdit,
    handleChangeEditName,
    handleChangeEditMeasure,
    hiddenEdit,
    handleChangeEditWeight,
    handleAutoEditText,
    handleEditIngredient,
    handleChoice,
    choice,
    weight,
    measure,
    resetIngForm,
    setMeasure,
    handleChangeName,
    handleChangeWeight,
    handleChangeMeasure,
    filtrMassiv,
    handleAutoText,
    hidden,
    ingredient,
    choiceIngredient,
    setChoiceIngredient,
    isDisabled
  }

}

                                            /* СОРТИРОВКИ */
//Сортировка по категории бюда
export function sortCategory(arr) {
  let news = [...arr]
  return news.sort(function(a, b){
    return a.category === b.category ? 0 : a.category < b.category ? -1 : 1;
  })
}

//Сортировка по алфавиту
export function sortName(arr) {
  let news = [...arr]
  return news.sort(function(a, b){
    return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
  })
}

//Сортировка по алфавиту
export function sortAlf(arr) {
  let news = [...arr]
  return news.sort(function(a, b){
    return a.nameRu === b.nameRu ? 0 : a.nameRu < b.nameRu ? -1 : 1;
  })
}
 
//Сортировка по индексам массива снизу верх(по дате добавления)
export function sortArrDate(arr) {
  let newarr =[]
  for (let i = 0; i < arr.length; i++) {
    newarr.unshift(arr[i])
  }
  return newarr 
}

                                            /* ФИЛЬТРАЦИЯ */
// Сортировка продуктов по отмеченным, неотмеченным и дополнительным списком                                           
export  function filtrProduct(arr) {
  let  choiceFoodList;
  let checkFoodList ;
  let excessFoodList
  choiceFoodList = arr.filter(i=> i.choice)
  checkFoodList = arr.filter(i=> !i.choice && !i.excess)
  excessFoodList = arr.filter(i=> i.excess && !i.choice)
  return  {
    choiceFoodList, 
    checkFoodList, 
    excessFoodList
  }
}
 
//Фильтрация рецептов по владельцу
export function CurrentRecipe (arr, id) { 
  const NewArray = arr.filter((item) => {
   return item.owner === id;
 })
return NewArray
}

//расчет веса блюда
export function totals(recipe, ingredients) {
  const total = recipe.ingredients.reduce(function (acc, item) { 
  const ingBase = ingredients.find(i=> i._id ===item._id) // находим по id нужный ингредиент 
  const {purified, cooking, frying} = ingBase
  const weight = ingBase.measure.find(i=> i.nameRu === item.choisMeasure)
  if(weight !==undefined) {
    const trueWeight = weight.ratio*item.weight
    const purifiedWeight = trueWeight - (purified*trueWeight/100)
    if(recipe.heat) {
      const cookingWeight = purifiedWeight - (cooking*purifiedWeight/100)
      if(cooking === 0) {
        const fryingWeight = purifiedWeight - (frying*purifiedWeight/100)
        acc += fryingWeight
      } else {
        acc += cookingWeight
      }
    } else {
     acc += purifiedWeight
    }
  }
 return acc 
},0);
return total
}

/*export function filtrSearchSelect(recipes, category, type) {
  const NewArray = recipes.filter((item) => {
    if(category === '' && type === '') {
     return item
    } else if(category !== '' && type === '') {
     return item.category === category 
    } else if(category !== '' && type !== '') {
     return item.category === category && item.typeOfDiet.some( k => k.nameRu === type && k.boolean )
    } else if(category === '' && type !== '') {
     return  item.typeOfDiet.some( k => k.nameRu === type && k.boolean )
    }
    return
 })
 return NewArray 
}*/

// рандомный выбор, на входе массив id
export function getRandomElement(item) {
  let randIndex = Math.floor(Math.random() * item.length);
  return item.length > 0 && item[randIndex];
}
  
// Фильтрация массива по категориям блюд
export function filtrSearchMenu(arr, keyword) {
  const keywordSearch = keyword.toLowerCase();
    let idMenu = []
    const NewArray = arr.filter((item) => {
     return item.category.toLowerCase().includes(keywordSearch)
    })
    if(NewArray.length > 0) { 
      idMenu = NewArray.map(item=> item._id)
    } else {
      idMenu=[]
    }
    return idMenu
  }

  //Фильтр по наименованию рецепта
 export function filtrSearchRecipe(recipes, keyword) {
    const keywordSearch = keyword[0].toLowerCase()
    const NewArray = recipes.filter((i)=> {
      return i.name.toLowerCase().includes(keywordSearch)
    })
    return NewArray
  }

//Фильтр по поисковому слову
export function filtrSearchArr(arr, keyword) {
  const keywordSearch = keyword.toLowerCase();
  const NewArray = arr.filter((item) => {
   return item.name.toLowerCase().includes(keywordSearch)
  })
  return NewArray
}

//Фильтр по ингредиенту
export function handleSearchWord(recipes, ingredients, keyword) {
  const NewArray = recipes.filter((i)=> {
    return contains(i.ingredients, ingredients, keyword)
  }) 
  return NewArray
}
 //поиск рецептов со всеми ингредиентами указанным в поиске через запятую
 function contains(ingredient, ingredients, keyword) {
  if (!keyword) {
    return true;
  }
  for (let i = 0; i < keyword.length; i++) {
    for (let j = 0; j < ingredient.length; j++) {
      const name = ingredients.find(i=> i._id ===ingredient[j]._id).name /* находим по id нужный ингредиент*/
      if (name.toLowerCase().includes(keyword[i].toLowerCase())) {
        break;
      }
      if (j === ingredient.length - 1) {
        return false;
      }
    }
  }
  return true;
}


 //Фильтр настройкам(время приготовления, стоп-продукты, тип питания)
 export function filtrRecipeSettings(arr, settings) {
  const typeOfDietArr = settings.typeOfDiet.filter(s => s.boolean)
  const NewArray = arr.filter((res) => {
    return res.ingredients.every(item => !settings.stopProducts.includes(item._id)) 
  })
  const arr1 = NewArray.filter((res)=> {
    if(settings.cookingTime === 0) {
      return res
    } else {
      return res.time <= settings.cookingTime
    }
    
  })
  const arr2 = arr1.filter((res) => {
    return res.typeOfDiet.some(item => typeOfDietArr.some((i)=> item.nameEn === i.nameEn && item.boolean))
  })
  return arr2
}

                                            /* РАСЧЕТЫ */
//Общий список продуктов по всем выбранным датам
/*export function resulted (arr, servings) {  
    let result =[] 
    arr.forEach((v)=> {
      v.foodList.forEach((i) => { 
        if(result.length === 0 ) {
          result.push({weight: i.weight*servings, choisMeasure: i.choisMeasure, id: i.id, choice: false, excess: false})
        } else {
      //Проверка наличия этого ингредиента в result
          let check = result.find(с => с.id === i.id)
        //если есть в массиве то просто прибавляем вес ингредиента
          if(check) {
            result.forEach((z) => {
              if(z.id === i.id) {
                z.weight += Number(i.weight*servings)
              }
            })
          } //если нет ингредиента, то добавляем новый в result
          else if (check === undefined) {
            result.push({weight: i.weight*servings, choisMeasure: i.choisMeasure, id: i.id, choice: false, excess: false})   
          }    
        }      
      })
    });
    return result
  }*/

  export function resulted (arr, servings) {  
    const ingredients  = {};
    arr.forEach((v)=> {
      v.foodList.forEach((ingredient) => { 
        const key = `${ingredient.id}_${ingredient.choisMeasure}`;
        if (ingredients[key]) {
          ingredients[key].weight += Number(ingredient.weight*servings);
        } else {
          ingredients[key] = {
            id: ingredient.id,
            weight: ingredient.weight*servings,
            choisMeasure: ingredient.choisMeasure,
            choice: false, 
            excess: false,
          };
        }
      });
       
    });
    const combinedIngredients = Object.values(ingredients);  
    return combinedIngredients
  }
 
//Расчет количества продуктов в списке покупок
export function checklist(dex, recipes, ingredients) { 
  let food=[]  
  Object.values(dex).forEach((d) => {  
      if(Array.isArray(d) && d.length> 0) {  
        const fin = d.filter(i=>!i.id) 
        fin.forEach(item => {
          let showRecipe = recipes.find(r => r._id === item) 
          if(showRecipe !== undefined) {
      showRecipe.ingredients.forEach((i, index) =>  {
        const ingBase = ingredients.find(c=> c._id === i._id) 
        //const measure = i.measure[0].nameRu
        //на первой позиции
        if(food.length === 0 ) {
          let weight
          if(i.choisMeasure === ingBase.mainMeasure) {
            weight =  Number(i.weight)/showRecipe.servings
          } else if(i.choisMeasure !== ingBase.mainMeasure) {
              const crow = ingBase.measure.find(function (j) { 
                return j.nameRu.includes(i.choisMeasure);  
              })
              weight = (crow.ratio*Number(i.weight))/showRecipe.servings
            }
            food.push({weight: weight, choisMeasure: ingBase.mainMeasure, id: i._id})   
        } //если в result есть данные 
        else {
         
          //Проверка наличия этого ингредиента в result
          let check = food.find(с => с.id === i._id)  
          //если есть в массиве то просто прибавляем вес ингредиента
          if(check) {
            food.forEach((z) => {
              if(z.id === i._id) {
                if(i.choisMeasure === ingBase.mainMeasure) {
                  z.weight += Number(i.weight)/showRecipe.servings
                } else if(i.choisMeasure !== ingBase.mainMeasure) {
                  const crow = ingBase.measure.find(function (j) { 
                    return j.nameRu.includes(i.choisMeasure);  
                  })
                  z.weight +=  (crow.ratio*Number(i.weight))/showRecipe.servings
                }  
              }
            })
          } //если нет ингредиента, то добавляем новый в result
            else if (check === undefined) {
              let weight=0
              if(i.choisMeasure === ingBase.mainMeasure) {
                weight = Number(i.weight)/showRecipe.servings
              } else if(i.choisMeasure !== ingBase.mainMeasure) {
                const crow = ingBase.measure.find(function (j) { 
                  return j.nameRu.includes(i.choisMeasure);  
                })
                weight = (crow.ratio*Number(i.weight))/showRecipe.servings
              }
              food.push({weight: weight, choisMeasure: ingBase.mainMeasure, id: i._id})   
            }
        }
      })
    }
       })
      }
    })
    return food
}
/*
  //Перечсет списка продуктов в плане на 1 день
  function checklist1(upPlane, newDish) {
    let food=[...upPlane.foodList]  
    newDish.ingredients.map((n)=> {
      const have = upPlane.foodList.find(u=> u.id === n._id)
      const ingBase = ingredients.find(c=> c._id === n._id)
        if(have) {
          let weight=0
          food.map((z, index) => {
            if(z.id === n._id) {
              if(n.choisMeasure === z.choisMeasure) {
                z.weight += Number(n.weight)/newDish.servings
              } else if(n.choisMeasure !== z.choisMeasure) { 
                const crow = ingBase.measure.find(function (j) { 
                  return j.nameRu.includes(n.choisMeasure);  
                }) 
                weight = z.weight + (crow.ratio*Number(n.weight))/newDish.servings 
              } 
              food[index] = ({weight: weight, choisMeasure: ingBase.mainMeasure, id: n._id})  
            }
          })
        } else if (have === undefined) {
          let weight=0
          
          if(n.choisMeasure === ingBase.mainMeasure) {
            weight = Number(n.weight)/newDish.servings
          } else if(n.choisMeasure !== ingBase.mainMeasure) {
            const crow = ingBase.measure.find(function (j) { 
              return j.nameRu.includes(n.choisMeasure);  
            })
            weight = (crow.ratio*Number(n.weight))/newDish.servings
          }
          food.push({weight: weight, choisMeasure: ingBase.mainMeasure, id: n._id}) 
        
        }
    }) 
     
    return food
  }

  function changeMenus(upPlane, newDish) {  
    const dex = {...upPlane}
    dex.foodList = checklist1(upPlane, newDish)
    return dex
  }*/