//Пользовательские рецепты с возможностью добавить новые

import React from 'react';
import './UserRecipes.css';
import {AddEditRecipe, Recipe, SceletonRecipe, CounterPage} from '../' 
import { useSelector, useDispatch } from 'react-redux' 
import { getRecipeOwner } from '../../../store/api' 
 
export function UserRecipes(props) {
  const {ownerRecipe, sts, count} = useSelector(state => state.recipes)
  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.currentUser)
  const {status} = useSelector(state => state.ingredients)
  const [showModal, setShowModal] = React.useState(false)
  const [showModalEdit, setShowModalEdit] = React.useState(false)
  const [editCard, setEditCard] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1) 
  const allPages = Math.ceil(count/15)
  const pages = Array.from({length: allPages}, (_, i) => i + 1) 

 
  React.useEffect(() => {  
    dispatch(getRecipeOwner(currentPage))
  },[currentPage])

  //Изменение стейта открытия модального окна добавления рецепта
  function handleAddRecipe() {
    setShowModal(true)
  }

  //закрытие всех модалок
  function handleCloseModal() {
    setShowModal(false)
    setShowModalEdit(false)
    setEditCard(false)
  }

  //Изменение стейта открытия модального окна редактирования рецепта
  function handleEditRecipe(recipe) {
    setShowModalEdit(true)
    setEditCard(recipe)
  }

   //Счетчик изменения страниц
   function handleCounter(page) {
    //setFetching(true)
    setCurrentPage(page)
  } 

  return (
    <div className='recipes'> 
      <h1 className='recipe__title font35Bold'>Мои рецепты</h1> 
      <button onClick={handleAddRecipe} className='menuweek-button color my-recipe__add'>Добавить рецепт</button>
      <div className='row'>  
        <section className={`col col__article ${props.scrollTop > 0 ? 'col__article_sticky': ''}`}>
        { ownerRecipe.length > 0 && status=== 'resolved' ?
          ownerRecipe.map((item)=>(
            <Recipe recipe={item} onCardLike={props.onCardLike} loggedIn={props.loggedIn} editRecipe={handleEditRecipe} key={item._id}/>
          ))
          :
          <div className='col col__article-owner'>
          <SceletonRecipe />
          </div>
        }
        </section>
       
        {/*<AddEditRecipe onClose={handleCloseModal} imageError={props.imageError} editCard={editCard && editCard} postRecipe={handleCloseModal} isEditOpen={showModalEdit} isOpen={showModal} />*/}
        <AddEditRecipe onClose={handleCloseModal} postRecipe={handleCloseModal} card={editCard && editCard} isEditOpen={showModalEdit} isOpen={showModal}  />
      </div>
      <CounterPage handleCounter={handleCounter} pages={pages} currentPage={currentPage} totalCount={count}/>
    </div>
  )  
}