import homeicon from '../images/home.jpg'
import custom from '../images/custom.png'
import signup from '../images/signup.png'

export const calories = [
  'Калорийность',
  'Белки',
  'Жиры',
  'Углеводы',
]
 
export const advantages = [
  {
    title: 'Большая база рецептов',
    subtitle: 'Блюда для Лечебных столов, Цельного питания, Веган, Кето и Палео диет, а также Детское меню',
  },
  {
    title: 'План питания на любое количество дней',
    subtitle: 'От 1 дня и на месяц вперед',
  },
  {
    title: 'Удобный календарь для составления меню',
    subtitle: 'Просто выберите дни на которые нужно составить меню и кликните Создать меню',
  },
  {
    title: 'Индивидуальные настройки',
    subtitle: 'Выберите Вашу суточную калорийность, продукты аллергены, технику на кухне и многое другое',
  },
  {
    title: 'Все уже посчитано',
    subtitle: 'Калорийность, цена, вес порции и многое другое',
  },
  {
    title: 'Удобный список покупок',
    subtitle: 'Отмечайте продукты , которые Вы уже купили',
  },
  /*{
    title: 'План на 3 членов семьи (в разработке)',
    subtitle: 'Индивидуальные настройки на каждого члена семьи',
  },
  {
    title: 'Праздничное меню(в разработке)',
    subtitle: 'Составить меню на день рождение, юбилей, и новый год на количество гостей',
  },*/
]
export const profileCategory =[
  {
    id: '1pc',
    icon: homeicon,
    category: 'Мои данные',
    path: 'my-info',
  },
  {
    id: '2pc',
    icon: custom,
    category: 'Настройки меню',
    path: 'settings',
  },
  {
    id: '3pc',
    icon: custom,
    category: 'Мои рецепты',
    path: 'my-recipes',
  },
  {
    id: '4pc',
    icon: custom,
    category: 'Мои меню',
    path: 'my-menu',
  },
  /*{
    id: '5pc',
    icon: custom,
    category: 'Список продуктов',
    path: 'food-list',
  },*/
  {
    id: '6pc',
    icon: signup,
    category: 'Выход',
    path: 'signout'
  },
]
/*
export const categoryFood = [
  'Завтрак',
  'Супы',
  'Основное блюдо',
  'Десерты и Выпечка',
  'Закуски',
  'Салаты',
  'Перекусы',
  'Напитки'
]

export const categoryHolydaysFood = [
  {
    nameRu: 'Основное блюдо',
    nameEn: 'dinners',
    boolean: true,
    counter: 0
  },
  {
    nameRu: 'Десерты и Выпечка',
    nameEn: 'dеsert',
    boolean: false,
    counter: 0
  },
  {
    nameRu: 'Салаты',
    nameEn: 'salads',
    boolean: false,
    counter: 1
  },
  {
    nameRu: 'Закуски',
    nameEn: 'snacks',
    boolean: false,
    counter: 0
  },
  {
    nameRu: 'Напитки',
    nameEn: 'drinks',
    boolean: false,
    counter: 0
  },
  {
    nameRu: 'Торты',
    nameEn: 'cakes',
    boolean: false,
    counter: 0
  }
 
]*/
export const subscriptions = [
  {
    title: 'Стандарт',
    subs: 'standart',
    text: 'Подходит для тех, чья семья питается по единому графику питания',
    price: 99,
    id: '99mbp',
    stocks: '60 дней бесплатно, без тестовых платежей',
    active: true,
  },
  {
    title: 'Семейный',
    subs: 'family',
    text: 'Возможность создания меню для членов семьи, если не все питаются по одному меню. Например детское меню + традиционное + диета. до 3-х типов' ,
    price: 199,
    id: '199mbp',
    stocks: '60 дней бесплатно, без тестовых платежей',
    active: false,
  },
  {
    title: 'Премиум',
    subs: 'premium',
    text: 'Возможность создания неограниченного количества меню для разных типов питания.Заинтерисует диетологов, нутрициологов, которые разрабатывают план питания ежедневно для своих клиентов',
    price: 999,
    id: '999mbp',
    stocks: '60 дней бесплатно, без тестовых платежей',
    active: false,
  },
]
 
export const mealsArray = [
  {
    nameRu: 'Завтрак',
    nameEn: 'breakfast',
    boolean: true,
    dish: [
      {
        nameRu: 'Завтрак',
        nameEn: 'breakfasts',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Ланч',
    nameEn: 'lunch',
    boolean: false,
    dish: [
      {
        nameRu: 'Перекусы',
        nameEn: 'snacks',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Обед',
    nameEn: 'dinner',
    boolean: true,
    dish: [
      {
        nameRu: 'Супы',
        nameEn: 'soup',
        boolean: true
      },
      {
        nameRu: 'Основное блюдо',
        nameEn: 'dinners',
        boolean: true
      },
      {
        nameRu: 'Десерты и Выпечка',
        nameEn: 'dеsert',
        boolean: false
      },
      {
        nameRu: 'Салаты',
        nameEn: 'salads',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Полдник',
    nameEn: 'snack',
    boolean: false,
    dish: [
      {
        nameRu: 'Перекусы',
        nameEn: 'snacks',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Ужин',
    nameEn: 'supper',
    boolean: true,
    dish: [
      {
        nameRu: 'Супы',
        nameEn: 'soup',
        boolean: true
      },
      {
        nameRu: 'Основное блюдо',
        nameEn: 'dinners',
        boolean: true
      },
      {
        nameRu: 'Десерты и Выпечка',
        nameEn: 'dеsert',
        boolean: false
      },
      {
        nameRu: 'Салаты',
        nameEn: 'salads',
        boolean: true
      }
    ]
  },
  {
    nameRu: 'Поздний ужин',
    nameEn: 'lateDinner',
    boolean: false,
    dish: [
      {
        nameRu: 'Перекусы',
        nameEn: 'snacks',
        boolean: true
      }
    ]
  },
]


export const weekdayArray = [
  {
    nameRu: 'Каждый день',
    nameEn: 'everyday',
    boolean: true
  },
  {
    nameRu: 'Пн',
    nameEn: 'monday',
    boolean: true
  },
  {
    nameRu: 'Вт',
    nameEn: 'tuesday',
    boolean: true
  },
  {
    nameRu: 'Ср',
    nameEn: 'wednesday',
    boolean: true
  },
  {
    nameRu: 'Чт',
    nameEn: 'thursday',
    boolean: true
  },
  {
    nameRu: 'Пт',
    nameEn: 'friday',
    boolean: true
  },
  {
    nameRu: 'Сб',
    nameEn: 'saturday',
    boolean: true
  },
  {
    nameRu: 'Вс',
    nameEn: 'sunday',
    boolean: true
  },
]
/*
export const  periodAn = [
  {
    nameRu: 'Каждый день',
    nameEn: 'everyday',
    boolean: true
  },
  {
    nameRu: 'Через день',
    nameEn: 'two',
    boolean: false
  },
  {
    nameRu: 'Раз в 3 дня',
    nameEn: 'three',
    boolean: false
  },
]
*/
export const text = {
aboutInfo1: 'С давних времен приготовление пищи является основопологающим для нормального функционирования человеческого организма.Сегодня приготовление пищи это квест, который проходит каждая хозяйка. Как, а главное чем накормить своих домочадцев, когда один не ест одно, а второй- другое. И времени в нашем ультрадинамичном мире все меньше на себя.',
aboutInfo2: 'Данная программа облегчит вам жизнь не только выбрав и рассчитав время на приготовление еды на каждый день, но и поможет выбрать список и количество необходимых вам продуктов в зависимости от количества в семье человек и подобранного вам меню на неделю. При этом наша программа экономит ваши деньги и конечно же ваше время. При походе в магазин или заказе через интернет, вы не всегда уверены все ли вы включили в список. Или возможно купили больше чем был рассчитан ваш бюджет.',
aboutInfo3: 'Вкусно, полезно и легко - девиз нашей программы. Вы можете подобрать калораж который вам необходим, можете рассчитать праздничный стол в зависимости от количества присутствующих и сравнить затраты с походом в кафе.',
} 

export const pattern = {
  email: '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$',
  name: '^[a-zA-Zа-яА-ЯЁё\\s\\-]+$',
  phone: '^(\\+7||8)[\\s(]*\\d{3}[)\\s]*\\d{3}[\\s-]?\\d{2}[\\s-]?\\d{2}',
  floor: '^[0-9]{1,2}\\/[0-9]{1,2}'
}

export const errorMessage = {
  emailandPasswordError: 'Ошибка авторизации. Неверный email или пароль.',
  tokenError: 'При авторизации произошла ошибка. Токен не передан или передан не в том формате.',
  forbiddenErr: 'У вас нет доступа к администрации сайта',
  forbiddenErrEdit: 'У вас нет доступа к изменению данных поьзователей',
  registrationError: 'Пользователь с таким email уже существует.',
  searchError: 'Во время запроса произошла ошибка. Подождите немного и попробуйте ещё раз».',
  keywordNull: "Нужно ввести ключевое слово",
  badRequestErr: 'При обновлении профиля произошла ошибка.',
  internalServerErr: 'На сервере произошла ошибка.',
  emailError: 'Пользователь с таким email уже существует',
  registrError: 'При регистрации пользователя произошла ошибка.',
  conflictErr: 'Слишком большое количество запросов.Попробуйте чуть позже',
  logoutErr: 'Не удалось выйти из системы',
  imageNoFile: 'Неверный формат фотографии, попробуйте еще раз',
  '400': 'Не удалось сохранить изменения проверьте все ли данные вы внесли'
}


export const succesOk = {
  changeInfoUser: 'Данные успешно обновлены!',
  signinOk: 'Вы успешно зарегистрированы',
  subNo: 'Закончен срок действия бесплатной подписки',
  subOk: 'Подписка продлена',
  menuSave: 'Меню успешно сохранено'
}

export const infoMessage = {
  dontFindMovie: 'Ничего не найдено!',
  blockedSaveMenu: 'Невозможно сгенерировать меню на прошедшие даты, выберите ближайшие даты!',
  blockedUpdateMenu: 'Нельзя корректировать меню на прошедшие даты, выберите ближайщие даты',
  noDateSelected: 'Необходимо выбрать даты для формирования меню!',
  noSuitableDish: 'Мы каждый день обновляем базу рецептов, но сейчас не смогли найти подходящий рецепт :( Вы можете добавить свой рецепт или  сделать настройки менее жесткими ',
  savedSetting: 'Настройки обновлены!',
  errorUpdate: 'Не удалось обновить меню, попробуйте еще раз',
  errorCheckProduct: 'Этот ингредиент уже есть в списке продуктов, нажмите кнопку редактировать, если нужно изменить его количество!'
}

export const popupMessage = {
  Вес: 'Расчет веса блюда производится исходя из потерь при холодной и тепловой обработке',
  Калории: 'Калории - это всего лишь единица энергии. Если вы едите больше, чем используете , вы можете набрать вес или сбросить его, если будете есть недостаточно. Сколько вам нужно, зависит от вашего веса, пола и того, насколько вы активны, но это около 2000 в день.',
  Белки: 'Белок помогает нашим мышцам расти и восстанавливаться, а также обеспечивает вас незаменимыми аминокислотами. Когда дело доходит до белка, старайтесь употреблять более нежирные источники, такие как курица и рыба, или немясные источники, такие как яйца, молочные продукты, фасоль, орехи, семечки, тофу и бобовые.',
  Жиры: 'Нам всем нужно употреблять небольшое количество жира, потому что он защищает наши органы и помогает нам расти. Но нам нужно быть осторожными с тем, сколько жира мы едим и какие виды жиров, потому что в больших количествах они связаны с проблемами со здоровьем',
  Угдеводы: 'Углеводы являются отличным источником энергии. Нам всем нужны углеводы, но старайтесь готовить их из цельнозерновых продуктов, предпочитая черный хлеб, рис и макароны - они гораздо питательнее.',
  Веган: 'Блюдо подходит для веганов без мяса, молока, яиц, мёда, а также других веществ и добавок, произведённых с использованием животных.',
  ПП: 'Сбалансированное блюдо, включающие в себя достаточное количество белков, жиров, углеводов и нутриентов.',
  Традиционное: 'Блюдо, без продуктовых ограничений. Представлены все кухни мира',
  Вегетарианское: 'Блюдо без мяса и рыбы, но содержит молочные продукты, яйца или мед.',
  Сыроедение: 'Блюдо без тепловой обработки.',
  'Детское меню': 'Блюдо подходит для детского питания',
  Кето: 'Блюдо, с высоким содержанием жиров и низким содержанием углеводов',
  Палео: 'Блюдо подходит для палео-диеты',
  'Без глютена': 'Блюдо не содержат глютен',
  'Цельное питание': 'Блюдо из необработанных и нерафинированных растительных продуктов.',
}
export const authErrors = {
  badRequestErr: 400,
  unauthorizedErr: 401,
  forbiddenErr: 403,
  notFoundErr: 404,
  conflictErr: 409,
  internalServerErr: 500,
  mongoErr: 11000,
}


/*
export const settingMeals=[
  {
    nameRu: 'Супы',
    nameEn: 'soup',
    boolean: false
  },
  {
    nameRu: 'Основное блюдо',
    nameEn: 'dinners',
    boolean: false
  },
  {
    nameRu: 'Десерты и Выпечка',
    nameEn: 'dеsert',
    boolean: false
  },
  {
    nameRu: 'Салаты',
    nameEn: 'salads',
    boolean: false
  },
]*/
/*
export const categoryIng = [
  'Вода и напитки',
  'Зелень',
  'Злаки и зерновые',
  'Крупы',
  'Макаронные изделия',
  'Масла',
  'Мясо и мясопродукты',
  'Молочные продукты',
  'Овощи',
  'Овощи-корнеплоды',
  'Овощи ферментированные',
  'Орехи',
  'Полуфабрикаты',
  'Рыба',
  'Сахар и кондитерские изделия',
  'Салаты',
  'Семена',
  'Специи',
  'Соусы',
  'Фрукты',
  'Хлебобулочные изделия',
  'Ягоды',
  'Яйца',
]
export const allMeasure = [
  {
    nameEn: 'gramm',
    nameRu: 'г',
  },
  {
    nameEn: 'kg',
    nameRu: 'кг',
  },
  {
    nameEn: 'pc',
    nameRu: 'шт',
  },
  {
    nameEn: 'ml',
    nameRu: 'мл',
  },
  {
    nameEn: 'litre',
    nameRu: 'литр',
  },
  {
    nameEn: 'beam',
    nameRu: 'пучок',
  },
  {
    nameEn: 'stem',
    nameRu: 'стебель',
  },
  {
    nameEn: 'clove',
    nameRu: 'зубчик',
  },
  {
    nameEn: 'head',
    nameRu: 'головка',
  },
  {
    nameEn: 'pinch',
    nameRu: 'щепотка',
  },
  {
    nameEn: 'glass',
    nameRu: 'стакан',
  },
  {
    nameEn: 'teaspoon',
    nameRu: 'ч.л',
  },
  {
    nameEn: 'tablespoon',
    nameRu: 'ст.л',
  },
  {
    nameEn: 'taste',
    nameRu: 'по вкусу',
  },
]

export const typeFoods = [
  {
    nameEn: 'traditionalFood',
    nameRu: 'Традиционное',
    boolean: true,
  },
  {
    nameEn: 'vegan',
    nameRu: 'Веган',
    boolean: false,
  },
  {
    nameEn: 'vegetarian',
    nameRu: 'Вегетарианское',
    boolean: false,
  },
  {
    nameEn: 'properNutrition',
    nameRu: 'ПП',
    boolean: false,
  },
  
  {
    nameEn: 'rawFood',
    nameRu: 'Сыроедение',
    boolean: false,
  },
  {
    nameEn: 'kidsmenu',
    nameRu: 'Детское меню',
    boolean: false,
  },
  {
    nameEn: 'glutenFree',
    nameRu: 'Без глютена',
    boolean: false,
  },
  {
    nameEn: 'paleoDiet',
    nameRu: 'Палео',
    boolean: false,
  },
  {
    nameEn: 'ketoDiet',
    nameRu: 'Кето',
    boolean: false,
  },
  {
    nameEn: 'gapsDiet',
    nameRu: 'Гапс',
    boolean: false,
  },
  {
    nameEn: 'detox',
    nameRu: 'Детокс',
    boolean: false,
  },
  {
    nameEn: 'wholeFood',
    nameRu: 'Цельное питание',
    boolean: false,
  },

]
*/
export const mainTesises = [
  {
    title: 'Магия аромата и вкуса',
    subtitile: 'Лучшие блюда со всего мира',
  },
  {
    title: 'Волшебство цвета и формы',
    subtitile: 'Наслаждайтесь эстетикой блюд',
  },
  {
    title: 'Мы все посчитали за Вас',
    subtitile: 'Калории, витамины, цены блюд и многое другое',
  },
  {
    title: 'Гибкая настройка параметров',
    subtitile: 'Настройте 1 раз и блюда без глютена, мяса и аллергенов всегда под рукой',
  },
  {
    title: 'Cooking Time',
    subtitile: 'Подберите блюда по времени, которое вы готовы тратить на кухне',
  },
 
  {
    title: 'Позаботьтесь о здоровой микробиоте семьи',
    subtitile: 'Составьте правильный рацион, с разнообразными блюдами по сезону.',
  },
]

export const guide = [
  
]
/*
export const measure = {
  vegetables: ['г', 'шт', 'кг'],
  cereals: ['г', 'кг', 'столовая ложка', 'стакан', ],
  litleVegetables: ['г', 'шт', 'кг', 'зубчик', 'головка'],
  main: ['г','кг'],
  verdure: ['г', 'шт', 'кг', 'пучок', 'стебель'],
  pasta: ['г', 'кг', 'столовая ложка', 'стакан', 'чайная ложка'],
  broth: ['мл', 'литр', 'столовая ложка', 'стакан', 'чайная ложка'],
}*/

export const benefit = [
  {
    id: '1ben',
    title: 'Быстро и просто',
    text: 'Создавайте меню одним кликом, на любое количество дней одновременно, с возможностью его корректировать и сохранять историю меню',
  },
  {
    id: '2ben',
    title: 'Настрой под себя',
    text: 'Гибкие настройки меню, с учетом графика питания, типа питания или диеты, количества людей в семье, максимального времени готовки',
  },
  {
    id: '3ben',
    title: 'Список Продуктов',
    text: 'Получите список продуктов на любое количество дней из плана, с возможностью добавить доп продукты или отметить те что в наличии',
  },
  {
    id: '4ben',
    title: 'Рецепты для популярных типов питания',
    text: 'typeFoods',
  },
  {
    id: '5ben',
    title: 'График питания от 1 до 6 раз в день',
    text: 'mealsArray',
  },
  {
    id: '6ben',
    title: 'Праздничное меню',
    text: 'Возможность составить праздничное меню с учетом приглашенных гостей, количеством блюд в каждой категории',
  },
]