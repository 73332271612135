//Отображение списка типов питания

import React from 'react';
import { TypeFood } from '../';

export function TypesFood (props) {
   
  return (
    <ul className='form-settings__block_row'>
      {props.types && props.types.length > 0 
      ?  props.types.map((item)=> (
          <TypeFood item={item} key={item.nameEn} types={props.types} onClick={props.handleCheckBox}/>
        ))
      : ''
      }
    </ul>
  )
}

