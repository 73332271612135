import React from "react";
import { Dots } from "../../atoms"; 
import './Slider.css';
export function Slider(props) {  
  
  return (
    <div className="slider"> 
      <div className='slide-list' style={{ transform: `translateX(-${props.slide * props.offset}%)` }}> 
        {props.children} 
      </div> 
      {props.length > props.step && props.title !== 'slogan' &&
      <div className='slide__arrow'>
        <button onClick={() => props.changeSlide(props.slide > 0 && -1)} className={`gg-arrow-left ${props.type==='recipe' && ('gg-arrow-top')} ${props.slide===0 && ('gg-arrow-none')}`}/> 
        <button onClick={() => props.changeSlide(props.slide + 1 < props.nums && 1)} className={`gg-arrow-right ${props.type==='recipe' && ('gg-arrow-top')} ${props.slide === props.length-1 && ('gg-arrow-none')}`} /> 
      </div>
      }
      {props.length > props.step &&
        <Dots goToSlide={props.goToSlide} slideNumber={props.slide} slidesCount={props.nums}/>
      }
    </div>
  );
}

  