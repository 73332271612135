import React, { Component } from 'react'

export class PrintThisComponent extends Component {
  render() {
    return (
      <div>
      <button className="gg-print"
        onClick={() => window.print()}>
       
      </button>
      </div>
    )
  }
}
 